import { navigate } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';
import Header from '../headerComponent';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { useMutation, gql } from '@apollo/client';

const SUBMIT_CONTACT = gql`
  mutation contact($data: ContactUsFormInput!) {
    submitContact(data: $data) {
      errors
    }
  }
`;

const NewHomeHero = props => {
  const { homeHeroTitle, homeHeroDescription, homeHeroImage } = props;
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues: {},
  });
  const [contact, { loading }] = useMutation(SUBMIT_CONTACT);
  const isLoading = loading;
  const onSubmitHandler = values => {
    const { username } = values;
    if (!username) {
      navigate('https://app.agreed.io/signup');
      return;
    }

    const data = {
      email: username,
    };
    contact({ variables: { data } })
      .then(response => {
        console.log(response);
        toast.success('Message sent successfully', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
        });
      })
      .catch(errors => {
        toast.error('There was a problem. Please check again later.', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
        });
      });
  };
  return (
    <>
      <div className="new-home-hero-section">
        <Header />
        <div className="new-home-hero-section-wrapper">
          <div className="full-container">
            <div className="row">
              <div className="col-md-4 order-md-1 order-2">
                <div className="new-home-hero-section-text">
                  <h1 className="new-home-hero-section-title">
                    {homeHeroTitle}
                  </h1>
                  <p className="new-home-hero-section-description">
                    {homeHeroDescription}
                  </p>
                  <div>
                    <form
                      className="new-home-hero-section-input-section"
                      onSubmit={handleSubmit(onSubmitHandler)}
                    >
                      <input
                        type="email"
                        name="email"
                        id="email"
                        disabled={isLoading}
                        {...register('username')}
                        placeholder="name@agreed.com"
                        className="new-home-hero-section-input"
                      />
                      <button
                        aria-label="get started"
                        type="submit"
                        className="new-home-hero-section-button"
                      >
                        Get Started
                      </button>
                    </form>
                  </div>
                </div>
              </div>
              <div className="col-md-8 order-md-2 order-1">
                <div className="new-home-hero-section-image-col">
                  <GatsbyImage
                    alt={''}
                    image={homeHeroImage}
                    className="new-home-hero-section-image"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default NewHomeHero;
