import React from "react";
import DecisionsIcon from '../../../static/assets/decision.svg';
import TimerIcon from '../../../static/assets/clock.svg';
import PeopleIcon from '../../../static/assets/peopleIcon.svg'
const HightRiskComponent = (props) =>{
    const {
      hightRiskTitle,
      hightRiskProcessDecisions,
      hightRiskProcessTimer,
      hightRiskProcessLosingPeople,
    } = props;

    return (
      <div className="hight-risk-section">
        <div className="full-container">
          <h2 className="hight-risk-section-title">{hightRiskTitle}</h2>
          <div className="row">
            <div className="col-md-4">
              <div className="hight-risk-section-process">
                <DecisionsIcon className="hight-risk-section-process-icon" />
                <p className="hight-risk-section-process-text">
                  {hightRiskProcessDecisions}
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="hight-risk-section-process">
                <TimerIcon className="hight-risk-section-process-icon" />
                <p className="hight-risk-section-process-text timer">
                  {hightRiskProcessTimer}
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="hight-risk-section-process">
                <PeopleIcon className="hight-risk-section-process-icon" />
                <p className="hight-risk-section-process-text">
                  {hightRiskProcessLosingPeople}
                </p>
              </div>
            </div>
          </div>
          <p className="hight-risk-section-subtitle">Agreed helps you win.</p>
        </div>
      </div>
    );
}
export default HightRiskComponent;