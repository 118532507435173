import React from "react";
import { Autoplay, EffectCoverflow } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/effect-coverflow';
import JiraIcon from '../../../static/assets/jira.svg';
import SmartSheet from '../../../static/assets/smart.svg';
import Salesforce from '../../../static/assets/salesforce.svg'
import Asana from '../../../static/assets/asanaLogo.svg'
import Okta from '../../../static/assets/okta.svg';
import Airtable from '../../../static/assets/airtable.svg';
import Monday from '../../../static/assets/monday.svg';
import ClickUp from '../../../static/assets/clickup.svg';
import Wrike from '../../../static/assets/wrike.svg';
import Slack from '../../../static/assets/slack.svg';
import Microsoft from '../../../static/assets/microsoft.svg';
import Workfront from '../../../static/assets/workfront.svg';
import Trello from '../../../static/assets/trello.svg';
import GoogleForm from '../../../static/assets/googleForm.svg'
const IntegrationsComponent = (props) =>{
    const { integrationsSubtitle, integrationsTitle } = props;
    return (
      <div className="integrations-section">
        <p className="integrations-section-subtitle">{integrationsSubtitle}</p>
        <h4 className="integrations-section-title">{integrationsTitle}</h4>
        <div className="test-background">
          <div className="integrations-section-first-swiper">
            <Swiper
              slidesPerView={'auto'}
              modules={[Autoplay, EffectCoverflow]}
              effect={'coverflow'}
              grabCursor={true}
              centeredSlides={true}
              coverflowEffect={{
                rotate: 0,
                stretch: 160,
                scale: 0.92,
                depth: 80,
                modifier: 0.75,
                slideShadows: false,
              }}
              autoplay={{
                delay: 2500,
              }}
              loop
              breakpoints={{
                768: {
                  coverflowEffect: {
                    rotate: 0,
                    stretch: 340,
                    scale: 0.92,
                    depth: 80,
                    modifier: 1.75,
                    slideShadows: false,
                  },
                },
              }}
            >
              <SwiperSlide>
                <div className="integrations-section-rectangle jira">
                  <ClickUp className="integrations-section-rectangle-icon" />
                  <p className="integrations-section-rectangle-icon-name">
                    ClickUp
                  </p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="integrations-section-rectangle  asana">
                  <Monday className="integrations-section-rectangle-icon " />
                  <p className="integrations-section-rectangle-icon-name">
                    Monday
                  </p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="integrations-section-rectangle jira">
                  <Airtable className="integrations-section-rectangle-icon" />
                  <p className="integrations-section-rectangle-icon-name">
                    Airtable
                  </p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="integrations-section-rectangle workfront">
                  <Okta className="integrations-section-rectangle-icon" />
                  <p className="integrations-section-rectangle-icon-name">
                    Okta
                  </p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="integrations-section-rectangle asana">
                  <Microsoft className="integrations-section-rectangle-icon" />
                  <p className="integrations-section-rectangle-icon-name">
                    Microsoft Teams
                  </p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="integrations-section-rectangle jira">
                  <Slack className="integrations-section-rectangle-icon" />
                  <p className="integrations-section-rectangle-icon-name">
                    Slack
                  </p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="integrations-section-rectangle workfront">
                  <Wrike className="integrations-section-rectangle-icon" />
                  <p className="integrations-section-rectangle-icon-name">
                    Wrike
                  </p>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
          <div>
            <Swiper
              slidesPerView={'auto'}
              modules={[Autoplay, EffectCoverflow]}
              effect={'coverflow'}
              grabCursor={true}
              centeredSlides={true}
              coverflowEffect={{
                rotate: 0,
                stretch: 160,
                scale: 0.92,
                depth: 80,
                modifier: 0.75,
                slideShadows: false,
              }}
              autoplay={{
                delay: 2500,
              }}
              loop
              breakpoints={{
                768: {
                  coverflowEffect: {
                    rotate: 0,
                    stretch: 340,
                    scale: 0.92,
                    depth: 80,
                    modifier: 1.75,
                    slideShadows: false,
                  },
                },
              }}
            >
              <SwiperSlide>
                <div className="integrations-section-rectangle asana">
                  <JiraIcon className="integrations-section-rectangle-icon" />
                  <p className="integrations-section-rectangle-icon-name">
                    Jira Software
                  </p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="integrations-section-rectangle workfront">
                  <Asana className="integrations-section-rectangle-icon " />
                  <p className="integrations-section-rectangle-icon-name">
                    Asana
                  </p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="integrations-section-rectangle asana">
                  <Salesforce className="integrations-section-rectangle-icon" />
                  <p className="integrations-section-rectangle-icon-name">
                    Salesforce
                  </p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="integrations-section-rectangle jira">
                  <SmartSheet className="integrations-section-rectangle-icon" />
                  <p className="integrations-section-rectangle-icon-name">
                    Smartsheet
                  </p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="integrations-section-rectangle workfront">
                  <GoogleForm className="integrations-section-rectangle-icon" />
                  <p className="integrations-section-rectangle-icon-name">
                    Google Forms
                  </p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="integrations-section-rectangle asana">
                  <Trello className="integrations-section-rectangle-icon" />
                  <p className="integrations-section-rectangle-icon-name">
                    Trello
                  </p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="integrations-section-rectangle jira">
                  <Workfront className="integrations-section-rectangle-icon" />
                  <p className="integrations-section-rectangle-icon-name">
                    Workfront
                  </p>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </div>
    );
}
export default IntegrationsComponent