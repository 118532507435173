import React from 'react';
import NewHomeHero from '../components/homeCompnent/newHomeHero';
import { graphql } from 'gatsby';
import HightRiskComponent from '../components/homeCompnent/hightRiskComponent';
import AgreedHelpComponent from '../components/homeCompnent/agreedHelpComponent';
import CompaniesComponent from '../components/homeCompnent/companiesComponent';
import IntegrationsComponent from '../components/homeCompnent/integrationsComponent';
import NewFooterComponent from '../components/newFooterComponent';
import NewBannerComponent from '../components/homeCompnent/newBannerComponent';
import Layout from '../components/layout';
const HomePage = props => {
  const { pageContext } = props;
  const {
    data: {
      wpPage: {
        seo,
        bannerSection: {
          bannerSectionDescription,
          bannerSectionLink,
          bannerSectionTitle,
        },
        newHomeHero: {
          newHomeHeroSectionDescription,
          newHomeHeroSectionTitle,
          newHomeHeroSectionImage: {
            localFile: {
              childImageSharp: { gatsbyImageData: newHomeHeroSectionImage },
            },
          },
        },
        newHomeHightRisk: {
          newHomeHightRiskSectionLosingPeople,
          newHomeHightRiskSectionProcessDecisions,
          newHomeHightRiskSectionTimer,
          newHomeHightRiskSectionTitle,
        },
        agreedHelpSection: {
          agreedHelpSectionDescription,
          agreedHelpSectionSubtitle,
          agreedHelpSectionTitle,
          agreedHelpSectionImage: {
            localFile: {
              childImageSharp: { gatsbyImageData: agreedHelpSectionImage },
            },
          },
        },
        agreedHelpSavingTimeSection: {
          savingTimeSectionButtonText,
          savingTimeSectionDescription,
          savingTimeSectionTitle,
          savingTimeSectionImage: {
            localFile: {
              childImageSharp: { gatsbyImageData: savingTimeSectionImage },
            },
          },
        },
        beAmazedByWhatYourPeopleProduceSection: {
          beAmazedByWhatYourPeopleProduceSectionButtonTitle,
          beAmazedByWhatYourPeopleProduceSectionDescription,
          beAmazedByWhatYourPeopleProduceSectionTitle,
          beAmazedByWhatYourPeopleProduceSectionImage: {
            localFile: {
              childImageSharp: {
                gatsbyImageData: beAmazedByWhatYourPeopleProduceSectionImage,
              },
            },
          },
        },
        newHomeCompaniesSection: {
          companiesSectionDescription,
          companiesSectionTitle,
          companiesSectionBankOfAmerica: {
            localFile: {
              childImageSharp: {
                gatsbyImageData: companiesSectionBankOfAmerica,
              },
            },
          },
          companiesSectionFanniemae: {
            localFile: {
              childImageSharp: { gatsbyImageData: companiesSectionFanniemae },
            },
          },
          companiesSectionHeinz: {
            localFile: {
              childImageSharp: { gatsbyImageData: companiesSectionHeinz },
            },
          },
          companiesSectionHumana: {
            localFile: {
              childImageSharp: { gatsbyImageData: companiesSectionHumana },
            },
          },
          companiesSectionMufg: {
            localFile: {
              childImageSharp: { gatsbyImageData: companiesSectionMufg },
            },
          },
          companiesSectionNyse: {
            localFile: {
              childImageSharp: { gatsbyImageData: companiesSectionNyse },
            },
          },
          companiesSectionPanasonic: {
            localFile: {
              childImageSharp: { gatsbyImageData: companiesSectionPanasonic },
            },
          },
        },
      },
    },
  } = props;
  return (
    <Layout title={pageContext.title} seo={seo}>
      <NewHomeHero
        homeHeroTitle={newHomeHeroSectionTitle}
        homeHeroDescription={newHomeHeroSectionDescription}
        homeHeroImage={newHomeHeroSectionImage}
      />
      <HightRiskComponent
        hightRiskTitle={newHomeHightRiskSectionTitle}
        hightRiskProcessDecisions={newHomeHightRiskSectionProcessDecisions}
        hightRiskProcessTimer={newHomeHightRiskSectionTimer}
        hightRiskProcessLosingPeople={newHomeHightRiskSectionLosingPeople}
      />
      <AgreedHelpComponent
        helpTitle={agreedHelpSectionTitle}
        helpSubtitle={agreedHelpSectionSubtitle}
        helpDescription={agreedHelpSectionDescription}
        helpLink="Learn More"
        agreedScreenshots={agreedHelpSectionImage}
        isFirst={true}
        isSecond={false}
      />
      <AgreedHelpComponent
        helpSubtitle={savingTimeSectionTitle}
        helpDescription={savingTimeSectionDescription}
        helpLink={savingTimeSectionButtonText}
        agreedScreenshots={savingTimeSectionImage}
        isSecond={true}
      />
      <AgreedHelpComponent
        helpSubtitle={beAmazedByWhatYourPeopleProduceSectionTitle}
        helpDescription={beAmazedByWhatYourPeopleProduceSectionDescription}
        helpLink={beAmazedByWhatYourPeopleProduceSectionButtonTitle}
        agreedScreenshots={beAmazedByWhatYourPeopleProduceSectionImage}
        isEnd={true}
        isSecond={false}
      />
      <CompaniesComponent
        companiesTitle={companiesSectionTitle}
        companiesDescription={companiesSectionDescription}
        panasonicImage={companiesSectionBankOfAmerica}
        humanImage={companiesSectionFanniemae}
        bankOfAmericaImage={companiesSectionHeinz}
        mellonImage={companiesSectionHumana}
        mufgImage={companiesSectionMufg}
        nyseImage={companiesSectionNyse}
        pncImage={companiesSectionPanasonic}
      />
      <IntegrationsComponent
        integrationsSubtitle="INTEGRATIONS"
        integrationsTitle="Streamline your workflow with tools you already use."
      />
      <NewBannerComponent
        bannerTitle={bannerSectionTitle}
        bannerDescription={bannerSectionDescription}
        bannerLink={bannerSectionLink}
      />
      <NewFooterComponent />
    </Layout>
  );
};
export const query = graphql`
  query {
    wpPage(slug: { eq: "new-home-page-2" }) {
      bannerSection {
        bannerSectionDescription
        bannerSectionLink
        bannerSectionTitle
      }
      newHomeHero {
        newHomeHeroSectionDescription
        newHomeHeroSectionTitle
        newHomeHeroSectionImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
      newHomeHightRisk {
        newHomeHightRiskSectionLosingPeople
        newHomeHightRiskSectionProcessDecisions
        newHomeHightRiskSectionTimer
        newHomeHightRiskSectionTitle
      }
      agreedHelpSection {
        agreedHelpSectionButtonText
        agreedHelpSectionDescription
        agreedHelpSectionSubtitle
        agreedHelpSectionTitle
        agreedHelpSectionImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
      agreedHelpSavingTimeSection {
        savingTimeSectionButtonText
        savingTimeSectionDescription
        savingTimeSectionTitle
        savingTimeSectionImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
      beAmazedByWhatYourPeopleProduceSection {
        beAmazedByWhatYourPeopleProduceSectionButtonTitle
        beAmazedByWhatYourPeopleProduceSectionDescription
        beAmazedByWhatYourPeopleProduceSectionTitle
        beAmazedByWhatYourPeopleProduceSectionImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
      stepsSection {
        stepsSectionOne
        stepsSectionThree
        stepsSectionTitle
        stepsSectionTwo
      }
      newHomeCompaniesSection {
        companiesSectionDescription
        companiesSectionTitle
        companiesSectionBankOfAmerica {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
        companiesSectionFanniemae {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
        companiesSectionHeinz {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
        companiesSectionHumana {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
        companiesSectionMufg {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
        companiesSectionNyse {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
        companiesSectionPanasonic {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
      seo {
        metaDesc
        title
      }
    }
  }
`;
export default HomePage;
