import { Link } from "gatsby";
import React from "react";
import AgreedLogoBanner from '../../../static/assets/agreedLogoBanner.svg';
const NewBannerComponent = (props) =>{
    const { bannerTitle, bannerDescription, bannerLink } = props;
    return(
        <div className="banner-section">
            <div className="full-container">
                <AgreedLogoBanner/>
                <h5 className="banner-section-title">{bannerTitle}</h5>
                <p className="banner-section-description">{bannerDescription}</p>
                <Link className="banner-section-link" to="/contact">{bannerLink}</Link>
            </div>
        </div>
    )
}
export default NewBannerComponent